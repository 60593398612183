import type { I18n } from 'vue-i18n'
import defaultTheme from '@theme/defaultTheme.json'
import useCurrentUrl from '@base/composables/use-current-url'
import type { CmsPage, CmsConfig, CmsRedirect } from '#gql/default'

export default function useCms() {
  const nuxtApp = useNuxtApp()
  const state = useState('cms', () => ({
    config: null as CmsConfig | null,
    page: null as CmsPage | null,
    redirect: null as CmsRedirect | null,
  }))

  const $i18n = nuxtApp.$i18n as I18n & { locale: Ref<string> }

  useGqlHeaders({
    'Accept-Language': $i18n.locale.value,
  })

  const { currentHost } = useCurrentUrl()

  async function fetchCmsConfig() {
    const { data } = await useAsyncGql('FetchCmsConfig', {
      url: currentHost.value,
    })

    state.value.config = (data.value?.cmsConfig as CmsConfig) ?? null
  }

  async function fetchCmsPage(path: string) {
    // Reset state
    state.value.page = null
    state.value.redirect = null

    // Remove leading slash if present
    const normalizedPath = path.replace(/^\//, '')

    const { data } = await useAsyncGql('FetchCmsPage', {
      url: `${currentHost.value}/${normalizedPath}`,
    })

    state.value.page = (data.value?.cmsPage as CmsPage) ?? null
    state.value.redirect = (data.value?.cmsRedirect as CmsRedirect) ?? null
  }

  async function setDesign({
    pageId,
    configId,
    designId,
    backendToken,
  }: {
    pageId?: string
    configId?: string
    designId: string
    backendToken: string
  }) {
    useGqlHeaders({ Authorization: `Bearer ${backendToken}` })

    if (pageId) {
      await GqlSetPageDesignForPage({ pageId, designId })
    } else if (configId) {
      await GqlSetDefaultDesignForConfig({ configId, designId })
    }
  }

  const page = computed(() => state.value.page)
  const redirect = computed(() => state.value.redirect)
  const config = computed(() => state.value.config)
  const design = computed(() => {
    return (
      state.value.page?.design ??
      state.value.config?.defaultDesign ??
      defaultTheme
    )
  })

  return {
    page,
    redirect,
    config,
    design,
    fetchCmsPage,
    fetchCmsConfig,
    setDesign,
  }
}
